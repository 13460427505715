import { Spin, Timeline, Card, Row, Col } from 'antd';
import moment from 'moment';
import { ConvertDateTime, ConvertDate, ConvertOrderLocation, ConvertTankStatus } from './helper';

enum PartTypes {
  arm,
  fan,
  chassis,
  machine,
}

function MachinePartsHistory(props: { history: any; type: PartTypes; lang: string }) {
  const { history, type, lang } = props;
  function renderTimeline() {
    return history
      ?.sort((first: any, second: any) => moment(second.row_created_date).unix() - moment(first.row_created_date).unix())
      .map((timeline: any) => {
        return (
          <Timeline.Item key={'timelinekey' + timeline.row_created_date} label={ConvertDateTime(timeline.row_created_date, lang)}>
            <p>
              <b>{ConvertOrderLocation(timeline.location, lang)}</b>
            </p>
          </Timeline.Item>
        );
      });
  }

  return history ? (
    <Col xl={6} lg={12} sm={24}>
      <Card style={{ marginBottom: 5 }} title={type === PartTypes.arm ? (lang === 'tr' ? 'Kol İşlem Geçmişi' : 'Arm Transaction History') : type === PartTypes.chassis ? (lang === 'tr' ? 'Şase İşlem Geçmişi' : 'Chassis Transaction History') : type === PartTypes.fan ? (lang === 'tr' ? 'Fan İşlem Geçmişi' : 'Fan Transaction History') : lang === 'tr' ? 'Makine İşlem Geçmişi' : 'Machine Transaction History'}>
        <Timeline mode="left" style={{ width: '100%' }}>
          {renderTimeline()}
        </Timeline>
      </Card>
    </Col>
  ) : null;
}

function OrderHistory(props: { tank_status: any; machine_history: any; arm_history: any; chassis_history: any; fan_history: any; planned_date: any; lang: string }) {
  const { tank_status, machine_history, arm_history, chassis_history, fan_history, lang, planned_date } = props;

  return (
    <Card style={{ margin: 10, backgroundColor: 'FFFFFFFF' }} title={machine_history[0].serial_number + (lang === 'tr' ? ` Planlanan Üretim Tarihi: ${ConvertDate(planned_date, lang)}` : ` Planned Production Date: ${ConvertDate(planned_date, lang)}`)}>
      <div style={{ display: 'flex', width: '100%', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
        {machine_history ? (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <p style={{ textAlign: 'start', fontSize: '1.3rem' }}>{(lang == 'tr' ? '- Tank Durumu: ' : '- Tank Status: ') + ConvertTankStatus(tank_status, lang)}</p>
            <Row gutter={12}>
              <MachinePartsHistory history={machine_history} type={PartTypes.machine} lang={lang} />
              <MachinePartsHistory history={chassis_history} type={PartTypes.chassis} lang={lang} />
              <MachinePartsHistory history={fan_history} type={PartTypes.fan} lang={lang} />
              <MachinePartsHistory history={arm_history} type={PartTypes.arm} lang={lang} />
            </Row>
          </div>
        ) : (
          <Spin size="large" />
        )}
      </div>
    </Card>
  );
}

export default OrderHistory;
