import Axios from 'axios';
//export const API_ADDRESS = 'http://localhost:5000';
export const API_ADDRESS = 'https://agrotekapi.covisart.com:8443';

export async function GetCustomerOrderDetails(serial_number: string, customer: string) {
  const response = await Axios.get(`${API_ADDRESS}/api/Report/GetAllOrderStatusForCustomer/${serial_number}/${customer}`, {})
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}
