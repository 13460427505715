const moment = require('moment/min/moment-with-locales');

export function Capitalize(s: String) {
  if (s) {
    return s?.charAt(0)?.toUpperCase() + s?.slice(1);
  }
  return '';
}

export function ConvertDateTime(dateTime: string | null, lang: string) {
  switch (lang) {
    case 'tr':
      moment.locale('tr');
      break;
    case 'en':
      moment.locale('en');
      break;
  }
  if (dateTime) return moment(dateTime).format('Do MMMM YYYY, HH:mm');
  else return '';
}

export function ConvertDate(dateTime: string | null, lang: string) {
  switch (lang) {
    case 'tr':
      moment.locale('tr');
      break;
    case 'en':
      moment.locale('en');
      break;
  }
  if (dateTime) return moment(dateTime).format('Do MMMM YYYY');
  else return '';
}

export function ConvertOrderLocation(OrderLocation: string, lang: string, planned_date?: string) {
  switch (lang) {
    case 'tr':
      switch (OrderLocation) {
        case 'kollu':
          return 'Kollu Üretimde';
        case 'turbo':
          return 'Turbo Üretimde';
        case 'diger':
          return 'Diğer Üretimde';
        case 'test':
          return 'Testte';
        case 'depo':
          return 'Depoda';
        case 'bahce':
          return 'Bahçede';
        case 'onaybekliyor':
          return 'Üretim Onayı Bekliyor';
        case 'planlandı':
          return 'Üretim Planına Alındı ' + (planned_date ? 'Planlanan Tarih: ' + ConvertDateTime(planned_date ? planned_date : null, lang) : ' ');
        case 'teslimat':
          return 'Teslim Edildi';
        case 'onproduction':
          return 'Üretimde';
        case 'sendedpaint':
          return 'Boyaya Gönderildi';
        case 'sendedgalvaniz':
          return 'Galvanize Gönderildi';
        case 'receivedgalvaniz':
          return 'Galvaniz işleminde.';
        case 'receivedpaint':
          return 'Boya işleminde.';
        case 'sendedsaruhanli':
          return 'Saruhanlıya gönderiliyor.';
        case 'receivedsaruhanli':
          return 'Saruhanlı Depoda (Hazır).';
        default:
          return Capitalize(OrderLocation);
      }
    case 'en':
      switch (OrderLocation) {
        case 'kollu':
          return 'On Production';
        case 'turbo':
          return 'On Production';
        case 'diger':
          return 'On Production';
        case 'test':
          return 'On Test';
        case 'depo':
          return 'On Storage';
        case 'bahce':
          return 'On Garden';
        case 'onaybekliyor':
          return 'Waiting For Production Approval';
        case 'planlandı':
          return 'Included in the Production Plan ' + (planned_date ? 'Estimated Date: ' + ConvertDateTime(planned_date ? planned_date : null, lang) : ' ');
        case 'teslimat':
          return 'Delivered';
        case 'onproduction':
          return 'On Production';
        case 'sendedpaint':
          return 'Sended to Paint';
        case 'sendedgalvaniz':
          return 'Sended to Galvanize';
        case 'receivedgalvaniz':
          return 'Received Galvanize.';
        case 'receivedpaint':
          return 'Received Paint.';
        case 'sendedsaruhanli':
          return 'Sended Saruhanli.';
        case 'receivedsaruhanli':
          return 'On Saruhanli (Ready).';
        default:
          return Capitalize(OrderLocation);
      }
  }
}

export function ConvertOrderStatus(OrderStatus: string) {
  switch (OrderStatus) {
    case 'active':
      return 'Aktif';
    case 'cancelled':
      return 'İptal Edildi';
    case 'finished':
      return 'Tamamlandı';
    default:
      return Capitalize(OrderStatus);
  }
}

export function ConvertProductionTasksStatus(TasksStatus: string) {
  switch (TasksStatus) {
    case 'onproduction':
      return 'Üretiliyor';
    case 'ondelivery':
      return 'Sevkiyatta';
    case 'delivered':
      return 'Sevkiyat Tamamlandı';
    case 'completed':
      return 'Tamamlandı';
    default:
      return Capitalize(TasksStatus);
  }
}

export function ConvertTankStatus(TankStatus: string, lang: string) {
  switch (lang) {
    case 'tr':
      switch (TankStatus) {
        case 'delivered':
          return 'Teslim Edildi';
        case 'producted':
          return 'Üretim Tamamlandı (Hazır)';
        case 'confirmed':
          return 'Üretiliyor (Onaylandı)';
        case 'cancelled':
          return 'İptal Edildi';
        case 'pending':
          return 'Onay Bekliyor';
        default:
          return Capitalize(TankStatus);
      }
    case 'en':
      switch (TankStatus) {
        case 'delivered':
          return 'Delivered';
        case 'producted':
          return 'Producted (Ready)';
        case 'confirmed':
          return 'Producting (Confirmed)';
        case 'cancelled':
          return 'Cancelled';
        case 'pending':
          return 'Pending';
        default:
          return Capitalize(TankStatus);
      }
  }
}
