import { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Title from 'antd/lib/typography/Title';
import { Input, message, Select } from 'antd';
import OrderHistory from './OrderHistory';
import 'antd/dist/antd.dark.min.css';
import { GetCustomerOrderDetails } from './services/OrderService';

function App() {
  const [data, setData] = useState({} as { [key: string]: any });
  const [searchLoading, setSearchLoading] = useState(false);
  const [customer, setCustomer] = useState('');
  const [lang, setLang] = useState('tr');
  const { Option } = Select;

  const language: { [key: string]: any } = {
    tr: {
      title: 'Agrotek Sipariş Takip Sistemi',
      customeraddon: 'Müşteri Adı',
      ordernoaddon: 'Sipariş No',
      customerplaceholder: 'Lütfen Müşteri Adını giriniz.',
      ordernoplaceholder: 'Lütfen Sipariş Numaranızı Giriniz.',
    },
    en: {
      title: 'Agrotek Order Tracking System',
      customeraddon: 'Customer Name',
      ordernoaddon: 'Order No',
      customerplaceholder: 'Please Enter Your Customer Name',
      ordernoplaceholder: 'Please Enter Your Order Number',
    },
  };

  function getData(serial_number: string) {
    setSearchLoading(true);
    if (serial_number.length < 6) {
      message.info('Seri numarası 6 haneli olmalıdır.');
    }

    GetCustomerOrderDetails(serial_number, customer)
      .then((res) => {
        let result = {} as { [index: string]: any };

        res.machine_history.forEach((element: any) => {
          result[element.machine_id] = { machine_history: [...(result[element.machine_id] ? result[element.machine_id].machine_history : []), element] };
        });

        res.fan_history.forEach((element: any) => {
          result[element.machine_id] = { ...result[element.machine_id], fan_history: [...(result[element.machine_id].fan_history ? result[element.machine_id].fan_history : []), element] };
        });

        res.chassis_history.forEach((element: any) => {
          result[element.machine_id] = { ...result[element.machine_id], chassis_history: [...(result[element.machine_id].chassis_history ? result[element.machine_id].chassis_history : []), element] };
        });

        res.arm_history.forEach((element: any) => {
          result[element.machine_id] = { ...result[element.machine_id], arm_history: [...(result[element.machine_id].arm_history ? result[element.machine_id].arm_history : []), element] };
        });

        res.planned_date.forEach((element: any) => {
          result[element.machine_id] = { ...result[element.machine_id], planned_date: element.planned_date };
        });

        res.tank_status.forEach((element: any) => {
          result[element.machine_id] = { ...result[element.machine_id], tank_status: element.status };
        });

        console.log(result);

        setData(result);

        setSearchLoading(false);
      })
      .catch((err) => {
        setSearchLoading(false);
      });
  }

  return (
    <div className="App">
      <header className="App-header">
        <Select
          value={lang}
          style={{ width: 120, margin: 20, alignSelf: 'flex-end' }}
          onChange={(value) => {
            setLang(value);
          }}
        >
          <Option value="tr">Türkçe</Option>
          <Option value="en">English</Option>
        </Select>
        <img src={logo} style={{ color: 'white' }} className="App-logo" alt="logo" />
        <div style={{ margin: 16 }}>
          <Title>{language[lang].title}</Title>
          <Input size="large" value={customer} onChange={(e) => setCustomer(e.target.value)} addonBefore={language[lang].customeraddon} placeholder={language[lang].customerplaceholder} style={{ marginBottom: 10 }}></Input>
          <Input.Search disabled={customer.length < 1} loading={searchLoading} onSearch={(data) => getData(data)} size="large" addonBefore={language[lang].ordernoaddon} placeholder={language[lang].ordernoplaceholder} />
        </div>
      </header>
      {(() => {
        return Object.keys(data)?.map((key) => {
          return <OrderHistory tank_status={data[key].tank_status} planned_date={data[key].planned_date} machine_history={data[key].machine_history} chassis_history={data[key].chassis_history} fan_history={data[key].fan_history} arm_history={data[key].arm_history} lang={lang} />;
        });
      })()}
    </div>
  );
}

export default App;
